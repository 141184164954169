import { betslipValidation, removeSelected, resetStateBetslip, setMatchBetslip, setSelected } from '../../redux/bettingSlice';
import {getFromLocalStorage, removeItem, setLocalStorage} from './local-storage';

export const   addToSlip = (slip) => {
    let current_slip = getFromLocalStorage('betslip');
    if(current_slip){
        current_slip[slip.parent_match_id] = slip;
    } else {
        current_slip = {[slip.parent_match_id] : slip};
    }
    setLocalStorage('betslip', current_slip, 1*60*60*1000);
    return current_slip;
}

export const addToKironSlip = (slip) => {
    let current_slip = getFromLocalStorage('kironbetslip');
    if(current_slip){
        current_slip[slip.parent_match_id] = slip;
    } else {
        current_slip = {[slip.parent_match_id] : slip};
    }
    setLocalStorage('kironbetslip', current_slip, 1*60*60*1000);
    return current_slip;
}

export const getKironSlip = () => {
    return getFromLocalStorage('kironbetslip');
}

export const removeFromKironSlip = (parent_match_id) => {
    let current_slip = getFromLocalStorage('kironbetslip');
    delete current_slip[parent_match_id];
    setLocalStorage('kironbetslip', current_slip, 1*60*60*1000);
    return current_slip;
}

export const removeFromSlip = (match_id) => {
    let current_slip = getFromLocalStorage('betslip');
    delete current_slip[match_id];
    setLocalStorage('betslip', current_slip, 1*60*60*1000);
    return current_slip;
}

export const clearSlip = () => {
    removeItem('betslip');
}

export const clear_rep = (str) => {
    return str.replace(/\s/g, "");
};

export const getBetslip = () => {
    return getFromLocalStorage('betslip');
}

export const findPostableSlip = () => {
    let betslips = getBetslip() || {};
    var values = Object.keys(betslips).map(function (key) {
      return betslips[key];
    });
    return values;
  };
  export const findPostableReduxSlip = (betslips = {}) => {
    var values = Object.keys(betslips).map(function (key) {
      return betslips[key];
    });
    return values;
  };

export const getJackpotBetslip =  () =>{
    return  getFromLocalStorage('jackpotbetslip');
}

export const addToJackpotSlip = (slip) => {
    let current_slip = getFromLocalStorage('jackpotbetslip');
    if(current_slip){
        current_slip[slip.match_id] = slip;
    } else {
        current_slip = {[slip.match_id] : slip};
    }
    setLocalStorage('jackpotbetslip', current_slip, 1*60*60*1000);
    return current_slip;
}

export const removeFromJackpotSlip = (match_id) => {
    let current_slip = getFromLocalStorage('jackpotbetslip');
    delete current_slip[match_id];
    setLocalStorage('jackpotbetslip', current_slip, 1*60*60*1000);
    return current_slip;
}

export const clearJackpotSlip = () => {
    removeItem('jackpotbetslip');
}

export const clearKironSlip = () => {
    removeItem('kironbetslip');
}
export const formatNumber = (number) => {
    return number === undefined || number === 0 ? '0.00' : number.toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        .replace(".00", '');
}
